<!-- 消防安全 -->
<template>
  <div id="smoke-sensor">
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>消防安全</span>
      </div>
      <div class="content">
        <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="searchParam">
        
              <el-form-item label="设备类型">
                <el-select @change="searchLabel" v-model="searchParam.type" :popper-append-to-body="false" :clearable="false" placeholder="请选择设备类型">
                  <el-option v-for="item in deviceData" :key="item.id" :label="item.name"
                  :value="item.id"></el-option>
              </el-select>
          </el-form-item>
        </el-form>
        <!-- <div class="query-button">
          <el-button type="primary" icon="el-icon-search" @click="formQuery"
            >查询</el-button
          >
        </div> -->
      </div>
        <el-table  class="table" :data="smokeTable">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备名称" prop="deviceName">
          </el-table-column>
          <el-table-column align="center" label="设备编号" prop="deviceNum">
          </el-table-column>
          <el-table-column align="center" label="参数" prop="temp">
          </el-table-column>
          <el-table-column align="center" label="限定值" prop="limiting">
          </el-table-column>
          <el-table-column align="center" label="时间" prop="createTime">
          </el-table-column>
        </el-table>
        <div class="pagination">
          <button class="home-page" @click="homePage">首页</button>
          <el-pagination :current-page="requestParam.pageNum" :page-sizes="[10, 20, 30, 40]"
            layout="prev, pager, next, sizes,slot" prev-text="上一页" next-text="下一页" :total="total" background
            @size-change="changeSize" @current-change="changeCurrent">
          </el-pagination>
          <button class="tail-page" @click="tailPage">尾页</button>
          <div class="jump-pagination">
            <span class="text">跳至</span>
            <el-input v-model="inputValue" size="mini" class="pagination-input" @input="checkPageNum"
              @change="inputValueChange"></el-input>
            <span class="text">页</span>
            <button class="confirm" @click="confirm">确定</button>
          </div>
        </div>
        <!-- <el-table v-if="searchParam.type==='温湿噪'" class="table" :data="humidityTable">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备名称" prop="deviceName">
            <template slot-scope="">
              <span>温湿噪</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="温度" prop="temp">
          </el-table-column>
          <el-table-column align="center" label="湿度" prop="humidity">
          </el-table-column>
          <el-table-column align="center" label="状态" prop="status">
          </el-table-column>
        </el-table>
        <el-table v-if="searchParam.type==='SF6'" class="table" :data="sf6Table">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备名称" prop="deviceName">
            <template slot-scope="">
              <span>SF6</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="SF6浓度" prop="sf6">
          </el-table-column>
          <el-table-column align="center" label="温度" prop="temp">
          </el-table-column>
          <el-table-column align="center" label="湿度" prop="humidity">
          </el-table-column>
          <el-table-column align="center" label="状态" prop="status">
          </el-table-column>
        </el-table>
        <el-table v-if="searchParam.type==='水浸'" class="table" :data="waterTable">
          <el-table-column align="center" type="index" label="序号" width="75">
            <template slot-scope="scope">
              <span>{{ scope.$index + 1 }}</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="设备名称" prop="deviceName">
            <template slot-scope="">
              <span>水浸</span>
            </template>
          </el-table-column>
          <el-table-column align="center" label="参数值" prop="shuiJinWarning">
          </el-table-column>
          <el-table-column align="center" label="状态" prop="status">
          </el-table-column>
        </el-table> -->
      </div>
    </div>
  </div>
</template>
<script>
import { securityControlApi } from "@/api/securityControl.js";
export default {
  name: "",

  data() {
    return {
      requestParam: {
        id: "",
         pageSize:10,
        pageNum:1,
      },
      inputValue:1,
      total:0,
      searchParam:{
        type:1
      },
      deviceData:[
        {id:1,name:'烟雾'},
        {id:2,name:'温湿'},
        {id:3,name:'SF6'},
        {id:4,name:'水浸'}
      ],

      sf6Table:[],
      smokeTable:[],
      waterTable:[],
      humidityTable:[]
    };
  },

  mounted() {
    this.queryElectricalSafety();
  },
  created() {
    this.$store.commit("increment", "安全用电");
    this.$store.commit("selectChild", "消防安全");
    this.$store.commit("selectChildren", "");
    this.requestParam.id= sessionStorage.getItem("stationId")
  },
  methods: {
    searchLabel(){
      this.queryElectricalSafety()
    },

    queryElectricalSafety() {
      let obj={
        id:this.requestParam.id,
        type:this.searchParam.type,
        pageNum:this.requestParam.pageNum,
        pageSize:this.requestParam.pageSize,
      }
      securityControlApi.fireSafetyList(obj).then((res) => {
        if (res.code === 200) {
          this.smokeTable=res.data;
          this.total = res.total
          // this.sf6Table=res.data.sf6;
          // this.smokeTable=res.data.yanwu;
          // this.waterTable=res.data.shuijin;
          // this.humidityTable=res.data.wenshi;
        }
      });
    },
    formQuery() {
      this.requestParam.pageNum = 1;
      this.queryElectricalSafety();
    },
    checkPageNum(e) {
      let value = e.replace(/[^\d]/g, ""); // 只能输入数字
      value = value.replace(/^0+(\d)/, "$1"); // 第一位0开头，0后面为数字，则过滤掉，取后面的数字
      this.inputValue = value;
    },
    //页数输入
    inputValueChange() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      if (this.inputValue < 1) {
        this.inputValue = 1;
      } else {
        this.inputValue =
          this.inputValue < lastPage ? this.inputValue : lastPage;
      }
    },
    //首页
    homePage() {
      this.requestParam.pageNum = 1;
      this.queryElectricalSafety();
    },
    //尾页
    tailPage() {
      const lastPage = Math.ceil(this.total / this.requestParam.pageSize);
      this.requestParam.pageNum = lastPage;
      this.queryElectricalSafety();
    },
    confirm() {
      this.requestParam.pageNum = this.inputValue;
      this.queryElectricalSafety();
      this.inputValue = "";
    },
    changeSize(pageSize) {
      this.requestParam.pageSize = pageSize;
      this.queryElectricalSafety();
    },
    changeCurrent(pageNum) {
      this.requestParam.pageNum = pageNum;
      this.queryElectricalSafety();
    },
  
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
#smoke-sensor {
  width: 100%;
  min-height: 781px;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
}
.main {
  width: 100%;
  padding: 26px;
  box-sizing: border-box;
}
.table{
  margin-bottom: 16px;
}
/* 滚动条样式 */
::v-deep .el-table__body-wrapper::-webkit-scrollbar {
 
  height: 8px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  width: 24px;
  height: 40px;
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
::v-deep .el-table__body-wrapper {
  max-height: 65vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
